/* home.css */
#home
{
    height: 90%;
    padding-bottom: 50px;
}

#home h1, p, #spotify-text
{
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
}

#home a
{
    text-decoration: none;
    color: var(--primary-color);
}


#home h1 {
    font-size: 2.5rem !important;
    animation-delay: 0.2s !important; 
}
  
#home p {
    margin-bottom: 35px;
    font-size: 1.4rem !important;
    animation-delay: 0.4s !important;
}

.highlighted
{
    padding-left: 8px;
    padding-right: 8px;
    color: lightgray !important;
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: color 0.3s ease;
}

.highlighted:hover
{
    color: var(--third-color) !important;
}

.service-text
{
    font-size: 1rem;
    animation-delay: 0.6s !important;
    padding-bottom: 20px;
    color: grey;
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
    animation-delay: 0.6s !important;
}

.service-text span {
    display: inline-flex; 
    align-items: center;  
    justify-content: center; 
    text-wrap: nowrap;
}

.service-image {
    height: 20px;
    width: auto; 
    display: block; 
    margin: 0 auto;
    margin-right: 10px;
}

.service-loader{
    height: 20px !important;
    width: auto !important;
    margin-top: 0 !important;
    margin-left: 10px;
}

figure
{
    margin: 0;
}
figcaption
{
    margin-top: 15px;
    font-size: 0.8rem;
    color: var(--secondary-color);
}

#profile-img
{
    width: 400px;
    height: auto;
    background-color: var(--primary-color);
    border-radius: 20px;
}

#contact-me
{
    margin-bottom: 20px;
    height: 40px;
    width: 120px;
    border-radius: 5px;
    opacity: 0;
    display: inline-block;
    margin-top: 10px;
    background: transparent;
    transform-origin: top left;
    color: var(--primary-color);
    border: 2px var(--secondary-color) solid;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
    animation-delay: 0.8s !important;
    transition: background-color 0.5s ease, color 0.3s ease-out, transform 0.3s ease;
}

#contact-me:hover
{
    transform: scale(1.05);
    color: var(--third-color);
}

@media (max-width: 768px) {
    #contact-me
    {
        transform-origin: top;
    }
}