/* photos.css */

#photos {
    max-width: 100% !important;
}

#photos h1, #photos p {
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
}

#photos h1 {
    animation-delay: 0.2s !important; 
}

#photos p {
    animation-delay: 0.4s !important;
}

#photo-container {
    display: flex;
    flex-direction: column;
    height: 100vh; 
    padding-top: 30px;
}

#photo-scroll {
    display: flex;
    flex-direction: column;  
    padding-bottom: 20px; 
    width: 100%;
}

.photo-item {
    justify-content: center;
    transition: filter 0.3s ease; 
    margin-bottom: 20px;
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
    animation-delay: 0.6s !important;
    width: 90%
}

.photo-item img
{
    height: auto;
    width: 90%
}

@media (max-width: 768px) {
    #photo-scroll {
        margin: 0;
        padding: 0;
        align-items: center;
    }

    .photo-item {
        justify-content: center; 
    }
}
