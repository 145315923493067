
.circle
{
    background-color: white;
    width: 5px;
    height: 5px;
    margin-left: 15px;
    margin-right: 8px;
    border-radius: 50%;
    transform: translateY(1px);
}

.online
{
    background-color: lightgreen;
}

.idle
{
    background-color: orange;
}

.dnd
{
    background-color: rgb(255, 0, 0);
}

.offline
{
    background-color: gray;
}
