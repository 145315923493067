/* HTML: <div class="loader"></div> */
.loader {
    width: 35px;
    margin-top: 50px;
    opacity: 0;
    aspect-ratio: 1;
    --c:no-repeat linear-gradient(var(--third-color) 0 0);
    background:
      var(--c) 0 0,
      var(--c) 100% 0,
      var(--c) 100% 100%,
      var(--c) 0 100%;
    animation: 
      l2-1 2s infinite,
      l2-2 2s infinite,
      FlyUp 0.5s ease, 
      appear 0.5s ease forwards !important;
  }
  @keyframes l2-1 {
    0%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
    12.5%{background-size: 100% 4px,4px 0   ,0    4px,4px 0   }
    25%  {background-size: 100% 4px,4px 100%,0    4px,4px 0   }
    37.5%{background-size: 100% 4px,4px 100%,100% 4px,4px 0   }
    45%,
    55%  {background-size: 100% 4px,4px 100%,100% 4px,4px 100%}
    62.5%{background-size: 0    4px,4px 100%,100% 4px,4px 100%}
    75%  {background-size: 0    4px,4px 0   ,100% 4px,4px 100%}
    87.5%{background-size: 0    4px,4px 0   ,0    4px,4px 100%}
    100% {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
  }
  @keyframes l2-2 {
    0%,49.9%{background-position: 0 0   ,100% 0   ,100% 100%,0 100%}
    50%,100%{background-position: 100% 0,100% 100%,0    100%,0 0   }
  }

.instant
{
    animation-delay: 0s;
    animation: none;
    width: 20px;
    height: auto;
    margin: 0 auto;
}

.point-five-seconds
{
    animation-delay: 0.5s !important; 
} 

.one-second
{
    animation-delay: 1s !important;
}

.one-five-seconds
{
    animation-delay: 1.5s !important;
}

.two-seconds
{
    animation-delay: 2s !important;
}

.roles-delay
{
    animation-delay: 1.2s !important;
}

.members-delay
{
    animation-delay: 0.8s !important;
}

.channels-delay
{
    animation-delay: 1.6s !important;
}