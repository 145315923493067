/* styles.css */

:root {
    --primary-color: #ffffff;
    --background-color: #1e1c23;
    --secondary-color: #464646;
    --third-color: #0099ff;
}

[data-theme='dark'] {
    --primary-color: #ffffff;
    --background-color: #000000;
    --secondary-color: #464646;
}

[data-theme='light'] {
    --primary-color: #000000;
    --background-color: #ffffff;
    --secondary-color: #464646;
}

p{
    color: var(--primary-color);
    filter: brightness(0.7) invert(0.1);
}

.content
{
    height: 80vh;
}

button, input, textarea
{
    font-family: "source";
}

body {
    color: var(--primary-color);
    font-family: "source";
    background-color: var(--background-color);
    margin-left: 70px;
    margin-top: 0px;
    transition: background-color 0.5s ease, color 0.5s ease-out;
}

@keyframes FlyUp
{
    0%
    {
        transform: translateY(30%);
    }

    100%
    {
        transform: translateY(0%);
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes projectAppear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.8;
    }
}


@keyframes FlyRight {
    0%
    {
        transform: translateX(-30%);
    }

    100%
    {
        transform: translateX(0%);
    }
}

@keyframes blinking {
    0%, 50%
    {
        opacity: 0;
    }

    51%, 100%
    {
        opacity: 1;
    }
}

@keyframes flyUp 
{
    0%
    {
        opacity: 0;
        transform: translateY(70%);
    }

    100%
    {
        opacity: 1;
        transform: translateY(0%);
    }
}

@font-face {
    font-family: "source";
    src: url("../fonts/SourceCodePro-4alW.ttf");
}

@media (max-width: 768px) {

    body {
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
    }

    .content
    {
        margin-top: 140px;
        height: 100%;
        align-items: center;
    }

    .loader{
        margin: auto;
    }

    h1, h2, p {
        padding-left: 5%;
        padding-right: 5%;
    }
}
