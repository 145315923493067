/* home.css */

#contact h1, p
{
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
}

#contact h1 {
    animation-delay: 0.2s !important; 
}

#contact
{
    max-width: 100% !important;
}
  
#contact p {
    animation-delay: 0.4s !important;
}

#contact-form {
    margin-top: 40px;
    margin-right: 50px;
    max-width: 500px;
    padding-bottom: 8px;
    background-color: transparent;
    flex-grow: 1;
    flex-shrink: 1; 
    height: 60%;
}

form {
    text-align: start;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

label {
    margin-bottom: 8px;
    color: var(--third-color)
}

input, textarea {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid var(--primary-color);
    background-color: transparent;
    font-size: 16px;
    flex-grow: 0;
    color: var(--primary-color) !important;
    transition: color 0.3s ease;
    border-radius: 5px;
}

textarea {
    height: 150px;
}

input:focus, textarea:focus
{
    outline: none; 
    box-shadow: none; 
    border-color: initial;
    background-color: initial;
}

#contact-form label {
    display: block;
    margin-bottom: 5px; 
}

#contact-form input,
#contact-form textarea {
    width: 100%; 
    box-sizing: border-box; 
}


#contact-form textarea {
    height: 100px;
}

#message-container, #email-container, #name-container
{
    opacity: 0;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
}

#name-container
{
    animation-delay: 0.6s !important;
}

#email-container
{
    animation-delay: 0.8s !important;
}

#message-container
{
    animation-delay: 1s !important;
}

form button
{
    margin-bottom: 20px;
    height: 40px;
    width: 120px;
    border-radius: 5px;
    opacity: 0;
    display: inline-block;
    margin-top: 10px;
    background: transparent;
    color: var(--primary-color);
    border: 2px var(--secondary-color) solid;
    animation: FlyUp 0.5s ease, appear 0.5s ease forwards !important;
    animation-delay: 1.2s !important;
    transition: background-color 0.5s ease, color 0.3s ease-out, transform 0.3s ease;
    transform-origin: top left;
}

form button:hover
{
    transform: scale(1.05);
    color: var(--third-color);
}


@media (max-width: 768px) {

    #contact
    {
        margin: 10px;
    }

    #contact-form{
        width: 86%;
        justify-self: center;
        align-self: center;
        margin-top: 50px;
        margin: 50px 50px;
    }
    #contact
    {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-height: 100%;
        text-align: center;
    }
}
